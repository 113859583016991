<template>
  <h1 class="text-3xl font-semibold mb-16 text-center lg:text-left">
    Nuestros Servicios
  </h1>

  <div class="grid grid-cols-2 md:grid-cols-5">
    <CardComponent
      v-for="image in servicesArray"
      :key="image.id"
      :imageSrc="image.src"
      :cardTitle="image.title"
      class="mb-10"
    />
  </div>
</template>

<script setup lang="ts">
import CardComponent from "@/components/base/CardComponent.vue";

const servicesArray = [
  { id: 1, src: "bandera.webp", title: "Pines" },
  { id: 2, src: "bordado.webp", title: "Bordados" },
  { id: 3, src: "esculturas.webp", title: "Esculturas" },
  { id: 4, src: "fundicion.webp", title: "Fundición" },
  { id: 5, src: "grabado.webp", title: "Grabado" },
  { id: 6, src: "joyeria.webp", title: "Joyería" },
  { id: 7, src: "llaveros.webp", title: "Llaveros" },
  { id: 8, src: "monedas.webp", title: "Monedas" },
  { id: 9, src: "placas.webp", title: "Placas" },
  { id: 10, src: "promocional.webp", title: "Promocionales" },
  { id: 11, src: "reconocimiento.webp", title: "Reconocimientos" },
  { id: 12, src: "sublimacion.webp", title: "Sublimación" },
  { id: 13, src: "trofeos.webp", title: "Trofeos" },
  { id: 14, src: "torneado.webp", title: "Torneado" },
  { id: 15, src: "3d.webp", title: "Impresión 3D" },
];
</script>
