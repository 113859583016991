<template>
  <div class="w-full mx-auto p-6 bg-white shadow-md">
    <form @submit.prevent="submitForm" ref="emailForm">
      <div class="mb-4">
        <label for="topic" class="block text-gray-600">Asunto:</label>
        <input
          v-model="formData.topic"
          type="text"
          id="topic"
          name="topic"
          class="w-full px-4 py-2 border focus:outline-none focus:ring focus:border-[#181f35]"
          required
        />
      </div>

      <div class="mb-4">
        <label for="from" class="block text-gray-600">Email:</label>
        <input
          v-model="formData.from"
          type="email"
          id="from"
          name="from"
          class="w-full px-4 py-2 border focus:outline-none focus:ring focus:border-[#181f35]"
          required
        />
      </div>

      <div class="mb-4">
        <label for="message" class="block text-gray-600">Mensaje:</label>
        <textarea
          v-model="formData.message"
          id="message"
          name="message"
          rows="4"
          class="w-full px-4 py-2 border focus:outline-none focus:ring focus:border-[#181f35]"
          required
        ></textarea>
      </div>

      <div class="text-center">
        <button
          type="submit"
          class="px-4 py-2 bg-[#d59b6a] text-white hover:bg-[#8c6648] focus:outline-none focus:ring focus:border-blue-300"
        >
          <div
            v-if="isLoading"
            class="w-5 h-5 border-t-4 border-black-500 border-solid rounded-full animate-spin"
          ></div>
          <div v-else>Enviar</div>
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { ref } from "vue";

const emailForm = ref(null);
const isLoading = ref();
const formData = { topic: "", from: "", message: "" };
const submitForm = async () => {
  try {
    isLoading.value = true;
    await axios.post(
      " https://5e9a57oic6.execute-api.us-east-2.amazonaws.com/default/sendEmail",
      {
        name: formData.topic,
        message: formData.message,
        email: formData.from,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    setTimeout(() => {
      emailForm.value.reset();
    }, 100);
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};
</script>
