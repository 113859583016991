<template>
  <nav class="flex flex-col justify-center items-center bg-white shadow p-4">
    <img src="@/assets/logo-nav.png" alt="Logo" class="w-1/3 sm:w-1/12" />

    <!--  <div class="pt-3 hidden md:block">
      <router-link to="/">
        <span class="text-black ml-4 hover:underline">Home</span></router-link
      >
      <router-link to="/">
        <span class="text-black ml-4 hover:underline"
          >Gallery</span
        ></router-link
      >
      <router-link to="/">
        <span class="text-black ml-4 hover:underline">About</span></router-link
      >
    </div>-->
  </nav>
</template>
