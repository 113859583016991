<template>
  <div class="flex justify-center mt-[15%] lg:mt-0">
    <div class="flex flex-col lg:flex-row space-y-14">
      <div class="flex justify-center lg:mt-[20%]">
        <h1
          class="text-xl md:text-3xl lg:text-4xl text-center font-bold sm:font-bold"
        >
          El metal hecho obras maestras
        </h1>
      </div>
      <img
        src="@/assets/figura-1.png"
        alt="Fig 1"
        class="w-full h-full md:h-2/3 md:w-2/3 md:ml-[15%] lg:w-3/4 lg:h-3/4"
      />
    </div>
  </div>
</template>
