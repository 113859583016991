<template>
  <div
    class="flex flex-col items-center bg-white shadow-none rounded-lg overflow-hidden w-full hover:scale-110"
  >
    <img
      :src="require(`@/assets/services/${props.imageSrc}`)"
      alt="services"
      class="w-1/2 object-cover"
    />

    <div class="p-4">
      <h2 class="text-xl font-semibold mb-2">{{ props.cardTitle }}</h2>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";

const props = defineProps({
  imageSrc: String,
  cardTitle: String,
});
</script>
